import React from "react";
import Search from "../components/Home/Search";

const Courses = () => {
  return (
    <div className="min_height ">
      <Search />
    </div>
  );
};

export default Courses;
