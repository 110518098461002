import React from "react";
import Universties from "../components/Home/Universties";

const UniversitesPage = () => {
  return (
    <div>
      <Universties />
    </div>
  );
};

export default UniversitesPage;
